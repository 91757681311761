<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update FAQ
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
         <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <validation-observer ref="ngosFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <b-row no-body class="border rounded p-2">
                <br />
                <b-card-text class="my-50" />
                <b-col md="6">
                  <b-form-group label="Question" label-for="blog-edit-title" class="mb-2">
                    <validation-provider #default="{ errors }" name="Q">
                      <b-form-input id="alt-image" v-model="form.question" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Answer" label-for="blog-edit-title" class="mb-2">
                    <quill-editor
                      ref="ngosFormEditor"
                      v-model="form.answer"
                      :options="getEditorOptions('ngosFormEditor')"
                    />
                  </b-form-group>
                </b-col>
                <hr />
              </b-row>
              <b-row class="text-end" style="text-align: end"></b-row>
            </div>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button v-if="isLoading" variant="primary" disabled class="mr-1">
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
  BFormTags,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { quillEditor } from 'vue-quill-editor';
import { required, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref, reactive, getCurrentInstance } from '@vue/composition-api';
import Vue from 'vue';
import store from '@/store';
import { useRouter } from '@core/utils/utils';
import vSelect from 'vue-select';
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const { route } = useRouter();
    const isLoading = ref(false);
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const VisitorList = ref([]);
    const Tabimage = ref('media/svg/files/blank-image.svg');

    const form = ref({
      question: '',
      answer: '',
    });

    const ngosForm = reactive(form);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      refPreviewEl.value.src = base64;
    });

    const { inputImageRendererTab } = useInputImageRenderer(refInputE2, (base64) => {
      refPreviewE2.value.src = base64;
    });

    const { id } = route.value.params;
    const { ngos } = route.value.params;
    const ngosFormvalidate = ref();

    store.dispatch('setting/getFaqNgo', { id, ngos }).then((response) => {
      form.value = response.data.faq;
    });

   const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


    const linkHandler = (editorRef) => {
      const range = proxy.$refs[editorRef].quill.getSelection();
      if (range) {
        const url = prompt('Enter the URL');
        if (url) {
          const target = confirm('Open in new tab?') ? '_blank' : '_self';
          const linkValue = `<a href="${url}" target="${target}">${proxy.$refs[editorRef].quill.getText(
            range
          )}</a>`;
          proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue);
        }
      }
    };

    const getEditorOptions = (editorRef) => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
        
            ['bold', 'italic', 'underline', 'strike'],
            ['clean'],
            ['undo', 'redo'],
            ['paragraph'],

          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef),
          },
        },
      },
    });

    const save = () => {
      const formData = new FormData();

      ngosFormvalidate.value.validate().then((success) => {
        if (success) {
          let NgoId = form.value.n_g_o_id;
          isLoading.value = true;
          formData.append('question', form.value.question);
          formData.append('answer', form.value.answer);
          formData.append('n_g_o_id', form.value.n_g_o_id);
          formData.append('_method', 'put');

          store.dispatch('setting/updatefaqNgo', { id, formData, NgoId })
            .then((response) => {
              isLoading.value = false;
              Vue.swal({
                title: 'Updated',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
            });
        }
      });
    };

    return {
      isLoading,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      ngosForm,
      inputImageRenderer,
      ngosFormvalidate,
      VisitorList,
      form,
      id,
      ngos,
      save,
      required,
      email,
      getEditorOptions,
      imageHandler,
      insertImage,
      imageProperties,
    };
  },

  components: {
    BCard,
    vSelect,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
